import React from 'react';
import {Link} from "react-router-dom";

const InstructionHeader = () => {
    return (
        <div className='instruction_header'>
            <Link to='/'>
                <div className='back_button'>
                    <img src="/images/leftArrow.svg" alt=""/>
                </div>
            </Link>
            <img src="/images/mbd_logo.svg" alt=""/>
        </div>
    );
};

export default InstructionHeader;
