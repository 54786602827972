import React, {useState} from 'react';
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";
import { logout } from '../../store/authSlice';
import {useAppDispatch} from "../../store/hooks";

const MainHeader = () => {
    const dispatch = useAppDispatch();
    const [open, setOpen] = useState(false);

    const handleLogout = () => {
        dispatch(logout());
    };

    const user = useSelector((state: RootState) => state.auth.user);

    return (
        <div className='main_header'>
            <Link to='https://sakla.ru/'>
                <div className='back_button'>
                    <img src="/images/leftArrow.svg" alt=""/>
                </div>
            </Link>
            <img src="/images/mbd_logo.svg" alt=""/>
            <div className='right_part'>
                <Link to='/instruction'>
                    <div className='back_button'>
                        Инструкция
                    </div>
                </Link>
                {user && (
                    <div className='profile_button' onClick={() => setOpen(!open)}>
                        <div className='profile_button_avatar'>
                            <img src={user.avatar || '/images/stdavatar.svg'} alt=""/>
                        </div>
                        {open ? (
                            <img src='/images/arrowup.svg' alt=""/>
                        ) : (
                            <img src="/images/arrowdown.svg" alt=""/>
                        )}
                        {
                            open && (
                                <div className='profile_block'>
                                    <div className='profile_block_content'>
                                        <div className='profile_block_avatar'>
                                            <img src={user.avatar || '/images/stdavatar.svg'} alt=""/>
                                        </div>
                                        <div className='profile_block_name'>
                                            {user.firstname} {user.surname}
                                        </div>
                                    </div>
                                    <div className='profile_block_button' onClick={handleLogout}>
                                        <img src="/images/logout.svg" alt=""/>
                                        <span>Выйти из аккаунта</span>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                )}
            </div>
        </div>
    );
};

export default MainHeader;
