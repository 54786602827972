import React, { MouseEventHandler, useEffect, useRef, useState } from 'react';
import JustMap from "../JustMap";
import {useAppDispatch} from "../../store/hooks";
import {addMark} from "../../store/markSlice";
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {point} from "leaflet";

interface ImageConstructorProps {
    isOpen: boolean;
    onClose: () => void;
    address: string;
    markerPosition: [number, number] | null;
}

interface Point {
    x: number;
    y: number;
}

interface selectedPoint {
    type: string;
    coordinates: Point;
}

interface Shape {
    points: Point[];
    type: string;
    color: string;
    patternId: string;
    iconHref: string; // Добавляем путь к иконке
}

const colors: { [key: string]: string } = {
    'Проезжая часть': 'rgba(255, 0, 0, 0.3)',
    'Подъезд': 'rgba(0, 255, 0, 0.3)',
    'Парковка машин': 'rgba(255, 255, 0, 0.3)',
    'Арка': 'rgba(0, 0, 255, 0.3)',
    'Игровая зона': 'rgba(112,219,110, 0.3)',
    'Выход с игровой площадки': 'rgba(75, 0, 130, 0.3)'
};

const patterns: { [key: string]: string } = {
    'Проезжая часть': 'pattern1',
    'Подъезд': 'pattern2',
    'Парковка машин': 'pattern3',
    'Арка': 'pattern4',
    'Игровая зона': 'pattern5',
    'Выход с игровой площадки': 'pattern6'
};

const icons: { [key: string]: string } = {
    'Проезжая часть': '/images/road.svg',
    'Подъезд': '/images/entance.svg',
    'Парковка машин': '/images/parking.svg',
    'Арка': '/images/arch.svg',
    'Игровая зона': '/images/playZone.svg',
    'Выход с игровой площадки': '/images/exit.svg'
};

const ImageConstructor: React.FC<ImageConstructorProps> = ({ isOpen, onClose, address, markerPosition }) => {
    const [mainImage, setMainImage] = useState<{ imageSrc: string; shortFileName: string; file: File } | null>(null);
    const [mainImageName, setMainImageName] = useState<string | null>(null);
    const [thumbnails, setThumbnails] = useState<{ imageSrc: string; shortFileName: string; file: File }[]>([]);
    const [showMap, setShowMap] = useState<boolean>(true);
    const [selectedThumbnail, setSelectedThumbnail] = useState<number | null>(null);
    const [showObjectMenu, setShowObjectMenu] = useState<boolean>(false);
    const [selectedObject, setSelectedObject] = useState<string | null>(null);
    const [points, setPoints] = useState<Point[]>([]);
    const [shapes, setShapes] = useState<{ [key: string]: Shape[] }>({});
    const [entrances, setEntrances] = useState<{ [key: string]: Point[] }>({});
    const [arches, setArches] = useState<{ [key: string]: Point[] }>({});
    const [exits, setExits] = useState<{ [key: string]: Point[] }>({});
    const [entrancePoint, setEntrancePoint] = useState<Point | null>(null);
    const [isDragging, setIsDragging] = useState<boolean>(false);
    const [afterDragEnd, setAfterDragEnd] = useState<boolean>(false);
    const dispatch = useAppDispatch()
    const svgRef = useRef<SVGSVGElement | null>(null);
    const draggingPointIndex = useRef<number | null>(null);
    const [editing, setEditing] = useState(false);
    const [moving, setMoving] = useState(false);
    const [contextMenuVisible, setContextMenuVisible] = useState(false);
    const [contextMenuPosition, setContextMenuPosition] = useState({ x: 0, y: 0 });
    const [showConfirmMenu, setShowConfirmMenu] = useState<boolean>(false);
    const [confirmMenuPosition, setConfirmMenuPosition] = useState({x: 0, y: 0});
    const [selectedShape, setSelectedShape] = useState<Shape | null>(null);
    const [selectedPoint, setSelectedPoint] = useState<selectedPoint | null>(null);
    const [currentMousePosition, setCurrentMousePosition] = useState<{ x: number, y: number } | null>(null);
    const user = useSelector((state: RootState) => state.auth.user);

    useEffect(() => {
        setShapes({});
        setEntrances({});
        setArches({});
        setExits({});
    }, [markerPosition]);

    useEffect(() => {

    }, [isDragging])

    const handleMouseMoveTo = (e: React.MouseEvent<SVGSVGElement>) => {
        const point = getPointOnSVG(e);
        setCurrentMousePosition(point);
    }

    useEffect(() => {
        const handleMouseUp = (e: MouseEvent) => {
            if (isDragging) {
                setIsDragging(false);
                setAfterDragEnd(true);
            }
            const svg = svgRef.current;
            if (!svg) return;
            const point = svg.createSVGPoint();
            point.x = e.clientX;
            point.y = e.clientY;
            const transformedPoint = point.matrixTransform(svg.getScreenCTM()?.inverse());
            handleConfirmMenu({x: transformedPoint.x, y: transformedPoint.y})
               // setShowConfirmMenu(true);
        };

        const handleMouseMove = (e: MouseEvent) => {
            if (draggingPointIndex.current === null) return;
            setShowConfirmMenu(false);
            const svg = svgRef.current;
            if (!svg) return;
            const point = svg.createSVGPoint();
            point.x = e.clientX;
            point.y = e.clientY;
            const transformedPoint = point.matrixTransform(svg.getScreenCTM()?.inverse());
            setPoints(prevPoints => {
                const newPoints = [...prevPoints];
                newPoints[draggingPointIndex.current as number] = { x: transformedPoint.x, y: transformedPoint.y };
                return newPoints;
            });
            setIsDragging(true); // Set isDragging true during dragging
        };

        if (isDragging) {
            window.addEventListener('mousemove', handleMouseMove);
            window.addEventListener('mouseup', handleMouseUp);
        } else {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        }

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        };
    }, [isDragging]);

    useEffect(() => {
        if (afterDragEnd) {
            const timer = setTimeout(() => setAfterDragEnd(false), 100);
            return () => clearTimeout(timer);
        }
    }, [afterDragEnd]);

    const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>, index?: number) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const imageSrc = reader.result as string;
                const fileName = file.name;
                const shortFileName = fileName.length > 20 ? fileName.substring(0, 17) + '...' + fileName.substring(fileName.lastIndexOf('.')) : fileName;
                console.log(shortFileName)

                const newThumbnail = { imageSrc, shortFileName, file };

                if (index !== undefined) {
                    const newThumbnails = [...thumbnails];
                    newThumbnails[index] = newThumbnail;
                    setThumbnails(newThumbnails);
                } else {
                    setMainImage({ imageSrc, shortFileName, file });
                    setShowMap(false);
                    setThumbnails([...thumbnails, newThumbnail]);
                    //addToThumbnails(shortFileName);
                }
            };
            reader.readAsDataURL(file);
        }
    };

    const handleRemoveThumbnail = (index: number) => {
        const newThumbnails = [...thumbnails];
        const removedImage = newThumbnails[index]?.imageSrc;

        newThumbnails.splice(index, 1); // Удаляем объект из массива

        setThumbnails(newThumbnails);

        if (mainImage?.imageSrc === removedImage) {
            setMainImage(null);
            setMainImageName(null);
            setShowMap(false);
        }

        if (newThumbnails.length === 0) {
            setMainImage(null);
            setMainImageName(null);
            setShowMap(false);
        }
    };

    const handleThumbnailClick = (thumbnail: { imageSrc: string, shortFileName: string, file: File } | null, index: number) => {
        if (thumbnail) {
            setMainImage({ imageSrc: thumbnail.imageSrc, shortFileName: thumbnail.shortFileName, file: thumbnail.file });
            setMainImageName(thumbnail.shortFileName); // Сохраняем короткое имя файла
            setShowMap(false);
            setSelectedThumbnail(index);
        }
    };

    const handleMapClick = () => {
        setShowMap(true);
        setMainImage(null);
        setSelectedThumbnail(null);
    };

    const handleObjectMenuClick = (objectType: string) => {
        setSelectedObject(objectType);
        setShowObjectMenu(false);
    };

    interface IHandleContext {
        event: any;
        shape?: Shape | null;
        point?: selectedPoint | null;
    }

    const handleContextMenuHide = () => {
        setContextMenuVisible(false);
    }

    // @ts-ignore
    const handleContextMenu = (event, shape, point): IHandleContext => {
        if (!editing && points.length === 0) {
            event.preventDefault();
            if (shape) {
                setContextMenuPosition({
                    x: (shape.points[0].x + shape.points[1].x + shape.points[2].x) / 3 + 18,
                    y: (shape.points[0].y + shape.points[1].y + shape.points[2].y) / 3 - 16
                });
                setContextMenuVisible(true);
                setSelectedShape(shape);
            } else if (point) {
                setContextMenuPosition({
                    x: point.coordinates.x - 16,
                    y: point.coordinates.y - 16
                })
                setContextMenuVisible(true);
                setSelectedPoint(point);
            }
        }
    };

   const confirmMenuTimerRef = useRef<number | null>(null);

    const handleConfirmMenu = (point: Point) => {
        console.log(point);
        setConfirmMenuPosition({
            x: point.x,
            y: point.y
        })

        setShowConfirmMenu(false);

        if (confirmMenuTimerRef.current !== null) {
            clearTimeout(confirmMenuTimerRef.current);
        }

        // Set a new timer
        confirmMenuTimerRef.current = window.setTimeout(() => {
            setShowConfirmMenu(true);
        }, 1000);
    }

    const handleEditClick = () => {
        if (selectedShape) {
            setEditing(true);
            setPoints(selectedShape.points);
            setSelectedObject(selectedShape.type)
            renderPoints()
        }
        setContextMenuVisible(false);
    };

    const handleMoveClick = () => {
        setMoving(true);
        setContextMenuVisible(false);
    };

    const handleDeleteClick = () => {
        if (selectedShape) {
            const key = showMap ? 'map' : mainImage?.shortFileName || '';
            setShapes(prevShapes => {
                const newShapes = { ...prevShapes }
                newShapes[key] = newShapes[key].filter(shape => shape !== selectedShape);
                return newShapes;
            })
        } else if (selectedPoint) {
            const key = showMap ? 'map' : mainImage?.shortFileName || '';
            if (selectedPoint.type === 'entrance') {
                setEntrances(prevEntrances => {
                    const newEntrances = { ...prevEntrances }
                    newEntrances[key] = newEntrances[key].filter(point => (point.x !== selectedPoint.coordinates.x && point.y !== selectedPoint.coordinates.y));
                    return newEntrances;
                })
            } else if (selectedPoint.type === 'arch') {
                setArches(prevArches => {
                    const newArches = { ...prevArches }
                    newArches[key] = newArches[key].filter(point => (point.x !== selectedPoint.coordinates.x && point.y !== selectedPoint.coordinates.y));
                    return newArches;
                })
            } else if (selectedPoint.type === 'exit') {
                setExits(prevExits => {
                    const newExits = { ...prevExits }
                    newExits[key] = newExits[key].filter(point => (point.x !== selectedPoint.coordinates.x && point.y !== selectedPoint.coordinates.y));
                    return newExits;
                })
            }
        }
        setContextMenuVisible(false);
    };

    const handleDragPoint = (index: number, newPoint: Point) => {
        console.log('points', points);
        const newPoints = [...points];
        newPoints[index] = newPoint;
        setPoints(newPoints);
    };

    // @ts-ignore
    const handleDragShape = (newPosition) => {
        if (selectedShape) {
            console.log(selectedShape)
        }
        // if (selectedShape) {
        //     const updatedShapes = shapes.map(shape =>
        //         shape.id === selectedShape.id
        //             ? { ...shape, position: newPosition }
        //             : shape
        //     );
        //     setShapes(updatedShapes);
        // }
        setMoving(false);
    };


    const addPoint = (e: React.MouseEvent<SVGSVGElement>) => {
        if (!selectedObject || afterDragEnd) return;
        const point = getPointOnSVG(e);
        if (selectedObject === 'Подъезд' || selectedObject === 'Арка' || selectedObject === 'Выход с игровой площадки') {
            setEntrancePoint(point);
            handleConfirmMenu(point);
            return;
        }

            setPoints(prevPoints => {
                const newPoints = [...prevPoints, point];
                if (!editing) {
                    if (newPoints.length >= 3) {
                        handleConfirmMenu(point);
                    }
                }
                return newPoints;
            });
        setAfterDragEnd(false); // Reset afterDragEnd after successfully adding a point
    };

    const getPointOnSVG = (e: React.MouseEvent<SVGSVGElement>) => {
        const svg = svgRef.current;
        if (!svg) {
            return { x: 0, y: 0 }
        } else {
            const point = svg.createSVGPoint();
            point.x = e.clientX;
            point.y = e.clientY;
            const transformedPoint = point.matrixTransform(svg.getScreenCTM()?.inverse());
            return transformedPoint;
        }
    };

    const confirmShape = () => {
        if (points.length >= 3 && selectedObject && selectedObject !== 'Арка' && selectedObject !== 'Подъезд' && selectedObject !== 'Выход с игровой площадки') {
            const color = colors[selectedObject] || 'rgba(0, 0, 0, 0.3)';
            const patternId = patterns[selectedObject] || '';
            const iconHref = icons[selectedObject] || '';
            const key = showMap ? 'map' : mainImage?.shortFileName || '';
            if (!editing) {
                setShapes(prevShapes => {
                    const newShapes = { ...prevShapes };
                    if (!newShapes[key]) newShapes[key] = [];
                    newShapes[key].push({ points, type: selectedObject, color, patternId, iconHref });
                    return newShapes;
                });
            } else {
                setShapes(prevShapes => {
                    const newShapes = { ...prevShapes };
                    if (newShapes[key]) {
                        newShapes[key] = newShapes[key].map(shape =>
                            shape === selectedShape ? { ...shape, points } : shape
                        );
                    }
                    return newShapes;
                });
            }
            setPoints([]);
            setSelectedObject(null);
            setSelectedShape(null);
            setShowConfirmMenu(false);
            setEditing(false);
        }
        if (entrancePoint) {
            const key = showMap ? 'map' : mainImage?.shortFileName || '';
            if (selectedObject === 'Подъезд') {
                setEntrances(prevEntrances => {
                    const newEntrances = { ...prevEntrances };
                    if (!newEntrances[key]) newEntrances[key] = [];
                    newEntrances[key].push(entrancePoint);
                    return newEntrances;
                });
            } else if (selectedObject === 'Арка') {
                setArches(prevArches => {
                    const newArches = { ...prevArches };
                    if (!newArches[key]) newArches[key] = [];
                    newArches[key].push(entrancePoint);
                    return newArches;
                });
            } else if (selectedObject === 'Выход с игровой площадки') {
                setExits(prevExits => {
                    const newExits = { ...prevExits };
                    if (!newExits[key]) newExits[key] = [];
                    newExits[key].push(entrancePoint);
                    return newExits;
                });
            }
            setEntrancePoint(null);
            setSelectedObject(null);
            setShowConfirmMenu(false);
        }
    };

    const cancelShape = () => {
        setPoints([]);
        setEntrancePoint(null);
        setShowConfirmMenu(false);
        setEditing(false);
        setSelectedShape(null);
        setSelectedObject(null);
    };

    const handlePointMouseDown = (index: number) => (e: React.MouseEvent) => {
        e.stopPropagation();
        draggingPointIndex.current = index;
        setIsDragging(true);
    };

    const renderPath = (points: Point[], currentMousePosition?: Point | null) => {
        if (points.length === 0) return '';
        const pathPoints = [...points];
        if (currentMousePosition && !editing) {
            pathPoints.push(currentMousePosition);
        }
        const d = pathPoints.map((point, index) => `${index === 0 ? 'M' : 'L'} ${point.x} ${point.y}`).join(' ');
        return `${d} Z`;
    };

    const renderShapes = () => {
        const key = showMap ? 'map' : mainImage?.shortFileName || '';
        return (shapes[key] || []).map((shape, index) => (
            <g key={index}>
                <path
                    d={renderPath(shape.points)}
                    fill={`url(#${shape.patternId})`}
                    stroke={shape.color}
                    strokeWidth="2"
                />
                {shape.iconHref && (
                    <image
                        onMouseOver={(e) => handleContextMenu(e, shape, null)}
                        onTouchEnd={(e) => handleContextMenu(e, shape, null)}
                        onMouseOut={(e)=>handleContextMenuHide()}
                        style={{cursor: "pointer"}}
                        href={shape.iconHref}
                        x={(shape.points[0].x + shape.points[1].x + shape.points[2].x) / 3 - 14}
                        y={(shape.points[0].y + shape.points[1].y + shape.points[2].y) / 3 - 16}
                        height="32px"
                        width="32px"
                    />
                )}
            </g>
        ));
    };

    const renderEntrances = () => {
        const key = showMap ? 'map' : mainImage?.shortFileName || '';
        return (entrances[key] || []).map((point, index) => (
            <image
                onMouseOver={(e) => handleContextMenu(e, null, {coordinates: point, type: 'entrance'})}
                onTouchEnd={(e) => handleContextMenu(e, null, {coordinates: point, type: 'entrance'})}
                   style={{cursor: "pointer"}}
                   key={index} href="/images/entance.svg" x={point.x - 16} y={point.y - 16} height="32px" width="32px" />
        ));
    };

    const renderArches = () => {
        const key = showMap ? 'map' : mainImage?.shortFileName || '';
        return (arches[key] || []).map((point, index) => (
            <image
                onMouseOver={(e) => handleContextMenu(e, null, {coordinates: point, type: 'arch'})}
                onTouchEnd={(e) => handleContextMenu(e, null, {coordinates: point, type: 'arch'})}
                   style={{cursor: "pointer"}}
                   key={index} href="/images/arch.svg" x={point.x - 16} y={point.y - 16} height="32px" width="32px" />
        ));
    };

    const renderExits = () => {
        const key = showMap ? 'map' : mainImage?.shortFileName || '';
        return (exits[key] || []).map((point, index) => (
            <image
                onMouseOver={(e) => handleContextMenu(e, null, {coordinates: point, type: 'exit'})}
                onTouchEnd={(e) => handleContextMenu(e, null, {coordinates: point, type: 'exit'})}
                   style={{cursor: "pointer"}}
                   key={index} href="/images/exit.svg" x={point.x - 16} y={point.y - 16} height="32px" width="32px" />
        ));
    };

    const renderPoints = () => {
        return points.map((point, index) => (
            <circle key={index} cx={point.x} cy={point.y} r="8" style={{ cursor: 'pointer', pointerEvents: 'all' }} fill="red" onMouseDown={handlePointMouseDown(index)} />
        ));
    };

    const renderContextMenu = () => (
        <div className="context-menu" style={{ top: contextMenuPosition.y, left: contextMenuPosition.x }}
             onMouseOver={(e) => setContextMenuVisible(true)}
             onMouseOut={() => setContextMenuVisible(false)}
        >
            {/*<div className="context-menu-option" onClick={handleEditClick}>*/}
            {/*    <img src="/images/pen.svg" alt=""/>*/}
            {/*    <span>Редактировать</span>*/}
            {/*</div>*/}
            {/*<div className="context-menu-option" onClick={handleMoveClick}>*/}
            {/*    <img src="/images/cursor.svg" alt=""/>*/}
            {/*    <span>Перенести</span>*/}
            {/*</div>*/}
            <div className="context-menu-option" onClick={handleDeleteClick}>
                <img src="/images/trash.svg" alt=""/>
                <span>Удалить</span>
            </div>
        </div>
    );

    function convertSVGPointsToPoints(svgPoints: Point[], type: string) {
        return svgPoints.map(point => ({
            type: type,
            x: point.x,
            y: point.y
        }));
    }

    function convertShapes(shapes: Shape[]): Shape[] {
        return shapes.map(shape => ({
            ...shape, // копируем все свойства текущего элемента
            points: shape.points.map(point => ({
                x: point.x,
                y: point.y
            }))
        }));
    }


    const placeSource = (shortFileName: string) => {
        const source: {
            arches: any[];
            entrances: any[];
            exits: any[];
            shapes: any[];
        } = {
            arches: [],
            entrances: [],
            exits: [],
            shapes: []
        };
        if (arches[shortFileName]) {
            console.log(convertSVGPointsToPoints(arches[shortFileName], 'Арка'));
            source.arches = convertSVGPointsToPoints(arches[shortFileName], 'Арка')
        };
        if (entrances[shortFileName]) {
            source.entrances = convertSVGPointsToPoints(entrances[shortFileName], 'Подъезд')
        };
        if (exits[shortFileName]) {
            source.exits = convertSVGPointsToPoints(exits[shortFileName], 'Выход')
        };
        if (shapes[shortFileName]) {
            source.shapes = convertShapes(shapes[shortFileName]);
        }
        return source;
    }

    useEffect(() => {
        console.log(user);
    }, [user])

    const handleAddMark = async () => {
        if (user) {
            console.log('markerPosition', user)
            const map: {
                arches: any[];
                entrances: any[];
                exits: any[];
                shapes: any[];
            } = {
                arches: [],
                entrances: [],
                exits: [],
                shapes: []
            };
            if (arches.map) {
                console.log(arches.map);
                map.arches = convertSVGPointsToPoints(arches.map, 'Арка')
            }
            if (entrances.map) {
                map.entrances = convertSVGPointsToPoints(entrances.map, 'Подъезд')
            }
            if (exits.map) {
                map.exits = convertSVGPointsToPoints(exits.map, 'Выход')
            }
            if (shapes.map) {
                map.shapes = convertShapes(shapes.map);
            }

            const placesArray: {
                type_id: number;
                shortFileName: string | null;
                image: string;
                source: any;
            }[] = [];

            thumbnails.forEach((item, index) => {
                placesArray.push({
                    type_id: 1,
                    shortFileName: item.shortFileName,
                    image: item.imageSrc,
                    source: placeSource(item.shortFileName)
                })
            })

            console.log(user.id)

          await dispatch(addMark({
                name: address,
                created_by: user.id,
                address: markerPosition,
                source: map,
                places: placesArray
            }));

            onClose()

            // Перезагрузка страницы после выполнения dispatch
            window.location.reload()
        }
    }

    if (!isOpen) return null;

    return (
        <div className='popup_overlay' onClick={onClose}>
            <div className="popup_content" onClick={(e) => e.stopPropagation()}>
                <div className='popup_header'>
                    <div className='popup_header_first'>
                        <img src="/images/point.svg" alt="" />
                        <span>{address}</span>
                    </div>
                </div>
                <div className='popup_flex'>
                    {(mainImage) ? (
                        <div className='popup_image'>
                            <img src={mainImage.imageSrc} alt="" />
                            <svg
                                ref={svgRef}
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                }}
                                onClick={addPoint}
                                onMouseMove={handleMouseMoveTo}
                            >
                                <defs>
                                    <pattern id="pattern1" patternUnits="userSpaceOnUse" width="5" height="10" patternTransform="rotate(45)">
                                        <line x1="0" y1="0" x2="0" y2="10" stroke="red" strokeWidth="2" style={{ pointerEvents: 'none' }} />
                                    </pattern>
                                    <pattern id="pattern2" patternUnits="userSpaceOnUse" width="10" height="10">
                                        <rect width="10" height="10" fill="rgba(0, 255, 0, 0.8)" style={{ pointerEvents: 'none' }} />
                                    </pattern>
                                    <pattern id="pattern3" patternUnits="userSpaceOnUse" width="10" height="10" patternTransform="rotate(-45)">
                                        <line x1="0" y1="0" x2="10" y2="10" stroke="#FFCC00" strokeWidth="2" style={{ pointerEvents: 'none' }}/>
                                    </pattern>
                                    <pattern id="pattern4" patternUnits="userSpaceOnUse" width="10" height="10">
                                        <rect width="10" height="10" fill="rgba(0, 0, 255, 0.8)" style={{ pointerEvents: 'none' }}/>
                                    </pattern>
                                    <pattern id="pattern5" patternUnits="userSpaceOnUse" width="10" height="10">
                                        <rect width="10" height="10" fill="rgba(112,219,110, 0.8)" style={{ pointerEvents: 'none' }}/>
                                    </pattern>
                                    <pattern id="pattern6" patternUnits="userSpaceOnUse" width="10" height="10">
                                        <rect width="10" height="10" fill="rgba(75, 0, 130, 0.8)" style={{ pointerEvents: 'none' }}/>
                                    </pattern>
                                </defs>
                                {renderShapes()}
                                {renderEntrances()}
                                {renderArches()}
                                {renderExits()}
                                {renderPoints()}
                                {points.length > 0 && currentMousePosition && (
                                    <path d={renderPath(points, currentMousePosition)} fill={`url(#${selectedObject ? patterns[selectedObject] : ''})`} stroke="black" strokeWidth="2" style={{ pointerEvents: 'none' }} />
                                )}
                                {entrancePoint && (selectedObject === 'Подъезд' || selectedObject === 'Арка' || selectedObject === 'Выход с игровой площадки') && (
                                    <image href={selectedObject === 'Арка' ? "/images/arch.svg" : selectedObject === 'Выход с игровой площадки' ? "/images/exit.svg" : "/images/entance.svg"} x={entrancePoint.x - 16} y={entrancePoint.y - 16} height="32px" width="32px" />
                                )}
                            </svg>
                            {(contextMenuVisible && renderContextMenu())}
                            {showConfirmMenu && (
                                <div className='context-menu' style={{left: confirmMenuPosition.x, top: confirmMenuPosition.y}}>
                                    <div className="context-menu-option" onClick={confirmShape}>
                                        <img src="/images/confirm.svg" alt=""/>
                                        <span>Завершить</span>
                                    </div>
                                </div>
                            )}
                            {points.length === 0 && !entrancePoint && (
                                <button className='addObjectButton' onClick={() => setShowObjectMenu(!showObjectMenu)}>
                                    <img src="/images/addButton.svg" alt="" />
                                    <span>Добавить объект</span>
                                </button>
                            )}
                            {showObjectMenu && (
                                <div className='objectMenu'>
                                    <div className='objectMenu_button' onClick={() => handleObjectMenuClick('Арка')}>
                                        <img src="/images/arch.svg" alt="" />
                                        <span>Арка</span>
                                    </div>
                                    <div className='objectMenu_button' onClick={() => handleObjectMenuClick('Игровая зона')}>
                                        <img src="/images/playZone.svg" alt="" />
                                        <span>Игровая зона</span>
                                    </div>
                                    <div className='objectMenu_button' onClick={() => handleObjectMenuClick('Подъезд')}>
                                        <img src="/images/entance.svg" alt="" />
                                        <span>Подъезд</span>
                                    </div>
                                    <div className='objectMenu_button' onClick={() => handleObjectMenuClick('Парковка машин')}>
                                        <img src="/images/parking.svg" alt="" />
                                        <span>Парковка</span>
                                    </div>
                                    <div className='objectMenu_button' onClick={() => handleObjectMenuClick('Выход с игровой площадки')}>
                                        <img src="/images/exit.svg" alt="" />
                                        <span>Выход с игровой площадки</span>
                                    </div>
                                    <div className='objectMenu_button' onClick={() => handleObjectMenuClick('Проезжая часть')}>
                                        <img src="/images/road.svg" alt="" />
                                        <span>Проезжая часть</span>
                                    </div>
                                </div>
                            )}
                            {/*{showConfirmMenu && (*/}
                            {/*    <div className="confirmMenu">*/}
                            {/*        <button className="confirmButton" onClick={confirmShape}>Подтвердить</button>*/}
                            {/*        <button className="cancelButton" onClick={cancelShape}>Удалить</button>*/}
                            {/*    </div>*/}
                            {/*)}*/}
                        </div>
                    ) : showMap ? (
                        <div className='popup_image'>
                            <JustMap coordinates={markerPosition}></JustMap>
                            <svg
                                ref={svgRef}
                                style={{
                                    zIndex: 2,
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                }}
                                onClick={addPoint}
                                onMouseMove={handleMouseMoveTo}
                            >
                                <defs>
                                    <pattern id="pattern1" patternUnits="userSpaceOnUse" width="5" height="10" patternTransform="rotate(45)">
                                        <line x1="0" y1="0" x2="0" y2="10" stroke="red" strokeWidth="2" style={{ pointerEvents: 'none' }} />
                                    </pattern>
                                    <pattern id="pattern2" patternUnits="userSpaceOnUse" width="10" height="10">
                                        <rect width="10" height="10" fill="rgba(0, 255, 0, 0.8)" style={{ pointerEvents: 'none' }} />
                                    </pattern>
                                    <pattern id="pattern3" patternUnits="userSpaceOnUse" width="10" height="10" patternTransform="rotate(-45)">
                                        <line x1="0" y1="0" x2="10" y2="10" stroke="#FFCC00" strokeWidth="2" style={{ pointerEvents: 'none' }} />
                                    </pattern>
                                    <pattern id="pattern4" patternUnits="userSpaceOnUse" width="10" height="10">
                                        <rect width="10" height="10" fill="rgba(0, 0, 255, 0.8)" style={{ pointerEvents: 'none' }} />
                                    </pattern>
                                    <pattern id="pattern5" patternUnits="userSpaceOnUse" width="10" height="10">
                                        <rect width="10" height="10" fill="rgba(112,219,110, 0.8)" style={{ pointerEvents: 'none' }} />
                                    </pattern>
                                    <pattern id="pattern6" patternUnits="userSpaceOnUse" width="10" height="10">
                                        <rect width="10" height="10" fill="rgba(75, 0, 130, 0.8)" style={{ pointerEvents: 'none' }} />
                                    </pattern>
                                </defs>
                                {renderShapes()}
                                {renderEntrances()}
                                {renderArches()}
                                {renderExits()}
                                {renderPoints()}
                                {points.length > 0 && currentMousePosition && (
                                    <path
                                        d={renderPath(points, currentMousePosition)}
                                        fill={`url(#${selectedObject ? patterns[selectedObject] : ''})`}
                                        stroke="black"
                                        strokeWidth="2"
                                        style={{ pointerEvents: 'none' }}
                                    />
                                )}
                                {/*{points.length > 0 && currentMousePosition && (*/}
                                {/*    <line*/}
                                {/*        x1={points[points.length - 1].x}*/}
                                {/*        y1={points[points.length - 1].y}*/}
                                {/*        x2={currentMousePosition.x}*/}
                                {/*        y2={currentMousePosition.y}*/}
                                {/*        stroke="red"*/}
                                {/*        strokeWidth="2"*/}
                                {/*    />*/}
                                {/*)}*/}
                                {entrancePoint && (selectedObject === 'Подъезд' || selectedObject === 'Арка' || selectedObject === 'Выход с игровой площадки') && (
                                    <image href={selectedObject === 'Арка' ? "/images/arch.svg" : selectedObject === 'Выход с игровой площадки' ? "/images/exit.svg" : "/images/entance.svg"} x={entrancePoint.x - 16} y={entrancePoint.y - 16} height="32px" width="32px" />
                                )}
                            </svg>
                            {contextMenuVisible && renderContextMenu()}
                            {showConfirmMenu && (
                                <div className='context-menu' style={{left: confirmMenuPosition.x, top: confirmMenuPosition.y}}>
                                    <div className="context-menu-option" onClick={confirmShape}>
                                        <img src="/images/confirm.svg" alt=""/>
                                        <span>Завершить</span>
                                    </div>
                                </div>
                            )}
                            {points.length === 0 && !entrancePoint && (
                                <button className='addObjectButton' onClick={() => setShowObjectMenu(!showObjectMenu)}>
                                    <img src="/images/addButton.svg" alt="" />
                                    <span>Добавить объект</span>
                                </button>
                            )}
                            {showObjectMenu && (
                                <div className='objectMenu'>
                                    <div className='objectMenu_button' onClick={() => handleObjectMenuClick('Арка')}>
                                        <img src="/images/arch.svg" alt="" />
                                        <span>Арка</span>
                                    </div>
                                    <div className='objectMenu_button' onClick={() => handleObjectMenuClick('Игровая зона')}>
                                        <img src="/images/playZone.svg" alt="" />
                                        <span>Игровая зона</span>
                                    </div>
                                    <div className='objectMenu_button' onClick={() => handleObjectMenuClick('Подъезд')}>
                                        <img src="/images/entance.svg" alt="" />
                                        <span>Подъезд</span>
                                    </div>
                                    <div className='objectMenu_button' onClick={() => handleObjectMenuClick('Парковка машин')}>
                                        <img src="/images/parking.svg" alt="" />
                                        <span>Парковка</span>
                                    </div>
                                    <div className='objectMenu_button' onClick={() => handleObjectMenuClick('Выход с игровой площадки')}>
                                        <img src="/images/exit.svg" alt="" />
                                        <span>Выход с игровой площадки</span>
                                    </div>
                                    <div className='objectMenu_button' onClick={() => handleObjectMenuClick('Проезжая часть')}>
                                        <img src="/images/road.svg" alt="" />
                                        <span>Проезжая часть</span>
                                    </div>
                                </div>
                            )}
                            {/*{showConfirmMenu && (*/}
                            {/*    <div className="confirmMenu">*/}
                            {/*        <button className="confirmButton" onClick={confirmShape}>Подтвердить</button>*/}
                            {/*        <button className="cancelButton" onClick={cancelShape}>Удалить</button>*/}
                            {/*    </div>*/}
                            {/*)}*/}
                        </div>
                    ) : (
                        <div className='popup_image_input'>
                            <div className='image_place'>
                                <img src="/images/imagePlace.png" alt="" />
                                <p>
                                    <span onClick={() => document.getElementById('mainImageInput')?.click()}>
                                        Нажмите
                                    </span>, чтобы загрузить изображение
                                </p>
                            </div>
                            <input
                                id="mainImageInput"
                                type="file"
                                accept="image/*"
                                style={{ display: 'none' }}
                                onChange={(e) => handleImageUpload(e)}
                            />
                        </div>
                    )}
                    <div className='thumbnail_panel'>
                        <div className='thumbnail_panel_map' onClick={handleMapClick}>
                            <JustMap coordinates={markerPosition}></JustMap>
                        </div>
                        <div className='thumbnails'>
                            {thumbnails.map((thumbnail, index) => (
                                <div key={index} className='thumbnail'>
                                        <div className='thumbnail_image_container'
                                             style={{
                                                 border: selectedThumbnail === index ? '4px solid #37AFF2' : 'none',
                                                 borderRadius: '16px'
                                             }}
                                        >
                                            <img src={thumbnail.imageSrc} alt="" onClick={() => handleThumbnailClick(thumbnail, index)} />
                                            <img className='removeButton' src='/images/removeButton.svg' alt='' onClick={() => handleRemoveThumbnail(index)}/>
                                        </div>
                                </div>
                            ))}
                            <div className='thumbnail_placeholder'>
                                <input
                                    id={`thumbnailInput`}
                                    type="file"
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    onChange={(e) => handleImageUpload(e)}
                                />
                                <span onClick={() => document.getElementById(`thumbnailInput`)?.click()}>+</span>
                            </div>
                        </div>
                        <div className='thumbnail_panel_buttons'>
                            <div className='blue_button' onClick={handleAddMark}>Сохранить метку</div>
                            <div className='blue_button blue_button_white' onClick={onClose}>Отменить</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ImageConstructor;
