import React, { useEffect, useState } from 'react';
import axios from 'axios';
import html2canvas from 'html2canvas';

const TILE_SIZE = 256;

function long2tile(lon, zoom) {
    return Math.floor((lon + 180) / 360 * Math.pow(2, zoom));
}

function lat2tile(lat, zoom) {
    return Math.floor((1 - Math.log(Math.tan(lat * Math.PI / 180) + 1 / Math.cos(lat * Math.PI / 180)) / Math.PI) / 2 * Math.pow(2, zoom));
}

const getTile = async (x, y, zoom) => {
    console.log(x, y)
    const url = `https://a.tile.openstreetmap.org/${zoom}/${x}/${y}.png`;
    const response = await axios.get(url, { responseType: 'arraybuffer' });
    return URL.createObjectURL(new Blob([response.data], { type: 'image/png' }));
};

const generateMapImage = async (lat, lon, zoom, width, height) => {
    const xTile = long2tile(lon, zoom);
    const yTile = lat2tile(lat, zoom);
    const tilesX = Math.ceil(width / TILE_SIZE);
    const tilesY = Math.ceil(height / TILE_SIZE);
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');

    for (let x = 0; x < tilesX; x++) {
        for (let y = 0; y < tilesY; y++) {
            const img = new Image();
            img.src = await getTile(xTile + x, yTile + y, zoom);
            await new Promise((resolve) => {
                img.onload = () => {
                    ctx.drawImage(img, x * TILE_SIZE, y * TILE_SIZE, TILE_SIZE, TILE_SIZE);
                    resolve();
                };
            });
        }
    }

    return canvas.toDataURL();
};

const MapImage = ({ lat, lon, zoom, width, height, onImageLoaded }) => {
    const [mapImage, setMapImage] = useState(null);

    useEffect(() => {
        if (mapImage) {
            onImageLoaded();
        }
    }, [mapImage])

    useEffect(() => {
        const fetchMapImage = async () => {
            const image = await generateMapImage(lat, lon, zoom, width, height);
            setMapImage(image);
        };

        fetchMapImage();
    }, [lat, lon, zoom, width, height]);

    return (
        <div>
            {mapImage ? (
                <img src={mapImage} alt="Map" />
            ) : (
                <p>Loading map...</p>
            )}
        </div>
    );
};

export default MapImage;