import React, {useEffect, useRef, useState} from 'react';
import styles from './PrintingComponent.module.scss';
import {fetchMarks, Mark} from "../../store/markSlice";
import JustMap from "../JustMap";
import { fetchMarkById } from "../../store/markSlice";
import {useAppDispatch} from "../../store/hooks";
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {Point, Shape} from "../YardScreen/YardScreen";
import { toPng } from 'html-to-image';
import jsPDF from 'jspdf';
import MapImage from "../MapImage/MapImage";

interface PrintingComponentProps {
    isOpen: boolean;
    onClose: () => void;
    markId: number | undefined;
    height: number;
    width: number;
}

const PrintingComponent: React.FC<PrintingComponentProps> = ({isOpen, onClose, markId, height, width}) => {

    const { mark, status, error } = useSelector((state: RootState) => state.marks);
    const [mapImageLoaded, setMapImageLoaded] = React.useState<boolean>(false);
    const dispatch =  useAppDispatch()
    const printRef = useRef<HTMLDivElement>(null);

    const handleClose = () => {
        onClose();
    };

    const getMark = async () => {
        if (markId) {
            dispatch(fetchMarkById(markId));
        }
    }

    useEffect(() => {
        getMark();
    }, [])

    useEffect(() => {
        console.log(mark?.address)
    }, [mark])

    const handleMapLoad = () => {

    }

    useEffect(() => {
        if (isOpen && mark && mapImageLoaded) {
            const printContent = printRef.current;
            if (printContent) {
                toPng(printContent).then((imgData) => {
                    console.log(imgData)
                    const pdf = new jsPDF('landscape', 'mm', 'a4');
                    const imgProps = pdf.getImageProperties(imgData);
                    const pdfWidth = pdf.internal.pageSize.getWidth();
                    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

                    pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
                    pdf.autoPrint(); // Запускает печать сразу после создания PDF
                    window.open(pdf.output('bloburl'), '_blank');
                });
            }
        }
    }, [mark, isOpen, mapImageLoaded]);

    const renderPath = (points: Point[]) => {
        if (points.length === 0) return '';
        const d = points.map((point, index) => `${index === 0 ? 'M' : 'L'} ${point.x} ${point.y}`).join(' ');
        return `${d} Z`;
    };


    const renderShapes = (shapes: Shape[]) => {
        return shapes.map((shape, index) => (
            <g key={index}>
                <path
                    d={renderPath(shape.points)}
                    fill={`url(#${shape.patternId})`}
                    stroke={shape.color}
                    strokeWidth="2"
                />
                {shape.iconHref && (
                    <image
                        href={shape.iconHref}
                        x={(shape.points.reduce((acc, point) => acc + point.x, 0) / shape.points.length) - 16}
                        y={(shape.points.reduce((acc, point) => acc + point.y, 0) / shape.points.length) - 16}
                        height="32px"
                        width="32px"
                    />
                )}
            </g>
        ));
    };

    const renderPoints = (points: Point[], iconHref: string) => {
        return points.map((point, index) => (
            <image
                key={index}
                href={iconHref}
                x={(point.x || 0) - 16}
                y={(point.y || 0) - 16}
                height="32px"
                width="32px"
            />
        ));
    };


    if (!isOpen) return null;

    return (
        <div className='popup_overlay' id='to_print' style={{zIndex: 202}} onClick={handleClose}>
            {mark && (
                <>
                    <button className='closeButton' onClick={onClose}>
                        <img src='/images/closeICON.svg' alt='close icon svg'/>
                    </button>
                    <div className="popup_content" style={{zIndex: 203}} onClick={(e) => e.stopPropagation()} ref={printRef}>
                        <div className='popup_header'>
                            <div className='popup_header_first'>
                                <img src="/images/point.svg" alt="" />
                                <span>{mark.name}</span>
                            </div>
                        </div>
                        <div className='popup_flex'>
                            <div className='popup_image'>
                                {/*<img src="/images/mapScreen.png" alt=""/>*/}
                                <MapImage lat={mark?.address[0]}
                                          lon={mark?.address[1]}
                                          zoom={19}
                                          height={height}
                                          width={width}
                                          onImageLoaded={() => setMapImageLoaded(true)}
                                />
                                {/*<JustMap coordinates={mark?.address}></JustMap>*/}
                                <svg
                                    style={{
                                        zIndex: 2,
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                    }}
                                >
                                    <defs>
                                        <pattern id="pattern1" patternUnits="userSpaceOnUse" width="5" height="10" patternTransform="rotate(45)">
                                            <line x1="0" y1="0" x2="0" y2="10" stroke="red" strokeWidth="2" style={{ pointerEvents: 'none' }}/>
                                        </pattern>
                                        <pattern id="pattern2" patternUnits="userSpaceOnUse" width="10" height="10">
                                            <rect width="10" height="10" fill="rgba(0, 255, 0, 0.8)" style={{ pointerEvents: 'none' }}/>
                                        </pattern>
                                        <pattern id="pattern3" patternUnits="userSpaceOnUse" width="10" height="10" patternTransform="rotate(-45)">
                                            <line x1="0" y1="0" x2="10" y2="10" stroke="#FFCC00" strokeWidth="2" style={{ pointerEvents: 'none' }}/>
                                        </pattern>
                                        <pattern id="pattern4" patternUnits="userSpaceOnUse" width="10" height="10">
                                            <rect width="10" height="10" fill="rgba(0, 0, 255, 0.8)" style={{ pointerEvents: 'none' }}/>
                                        </pattern>
                                        <pattern id="pattern5" patternUnits="userSpaceOnUse" width="10" height="10">
                                            <rect width="10" height="10" fill="rgba(112,219,110, 0.8)" style={{ pointerEvents: 'none' }}/>
                                        </pattern>
                                        <pattern id="pattern6" patternUnits="userSpaceOnUse" width="10" height="10">
                                            <rect width="10" height="10" fill="rgba(75, 0, 130, 0.8)" style={{ pointerEvents: 'none' }}/>
                                        </pattern>
                                    </defs>
                                    {renderShapes(mark.source.shapes)}
                                    {renderPoints(mark.source.entrances, "/images/entance.svg")}
                                    {renderPoints(mark.source.arches, "/images/arch.svg")}
                                    {renderPoints(mark.source.exits, "/images/exit.svg")}
                                </svg>
                            </div>
                            <div className={styles.symbols}>
                                <h2 className={styles.symbols_header}>Условные обозначения</h2>
                                <div className={styles.symbols_content}>
                                    <div className={styles.symbols_block}>
                                        <img src="/images/arch.svg" alt=""/>
                                        <span>
                                            Арка
                                        </span>
                                    </div>
                                    <div className={styles.symbols_block}>
                                        <img src="/images/entance.svg" alt=""/>
                                        <span>
                                            Подъезд
                                        </span>
                                    </div>
                                    <div className={styles.symbols_block}>
                                        <img src="/images/exit.svg" alt=""/>
                                        <span>
                                            Выход с игровой площадки
                                        </span>
                                    </div>
                                    <div className={styles.symbols_blockZone}>
                                        <div className={styles.symbols_blockZone_inside}>
                                            <img src="/images/playZone.svg" alt=""/>
                                            <span>
                                                Игровая зона
                                            </span>
                                        </div>
                                        <div className={styles.playPattern}/>
                                    </div>
                                    <div className={styles.symbols_blockZone}>
                                        <div className={styles.symbols_blockZone_inside}>
                                            <img src="/images/parking.svg" alt=""/>
                                            <span>
                                                Парковка
                                            </span>
                                        </div>
                                        <div className={styles.parkPattern}/>
                                    </div>
                                    <div className={styles.symbols_blockZone}>
                                        <div className={styles.symbols_blockZone_inside}>
                                            <img src="/images/road.svg" alt=""/>
                                            <span>
                                            Проезжая часть
                                        </span>
                                        </div>
                                        <div className={styles.roadPattern}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default PrintingComponent;