'use client';
import React, {useEffect, useRef, useState} from 'react';
import {MapContainer, TileLayer, Marker, Popup, useMapEvent, useMap} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
//@ts-ignore
import L from 'leaflet';
//@ts-ignore
import { SimpleMapScreenshoter } from "leaflet-simple-map-screenshoter";

const customMarkerIcon = new L.Icon({
    iconUrl: '/images/Marker.svg',
    iconSize: [40, 61]
});

interface JustMapProps {
    coordinates: any;
    handleMapRef?: (mapReady: boolean) => void;
}

const JustMap = ({ coordinates, handleMapRef }: JustMapProps) => {
    const [mapRef, setMapRef] = useState(false);

    useEffect(() => {
        if (mapRef && handleMapRef) {
            handleMapRef(mapRef); // Передаем значение в родительский компонент
        }
    }, [mapRef, handleMapRef]);

    return (
        <div style={{ position: 'relative', height: '100%', width: '100%' }}>
            <MapContainer
                center={coordinates}
                zoom={19}
                style={{ height: '100%', width: '100%', borderRadius: '16px', zIndex: '1' }}
                // @ts-ignore
                whenReady={mapInstance => {
                    setMapRef(true); // По мере создания карты устанавливаем флаг
                }}
            >
                <TileLayer
                    url={`https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png`}
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
            </MapContainer>
        </div>
    );
};

export default JustMap;
