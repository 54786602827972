import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import {Point, Shape} from "../Components/YardScreen/YardScreen";

export interface Mark {
    id: number;
    name: string;
    avatar: string;
    created_at: string;
    firstname: string;
    lastname: string | null;
    created_by: number;
    image: string;
    address: [number, number];
    source: any;
    places: Place[];
}

interface Source {
    arches: Point[];
    entrances: Point[];
    exits: Point[];
    shapes: Shape[];
}

interface Place {
    shortFileName: string;
    id: number;
    image: string;
    source: Source;
}

interface MarkState {
    marks: Mark[];
    mark: Mark | null;
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
}

const initialState: MarkState = {
    marks: [],
    mark: null,
    status: 'idle',
    error: null,
};

export const deleteMark = createAsyncThunk('marks/delete', async (id: number) => {
    const response = await axios.delete(`https://api-moydvor.sakla.ru/api/yards/${id}`)
    return response.data;
})

export const fetchMarks = createAsyncThunk('marks/fetch', async () => {
    const response = await axios.get(`https://api-moydvor.sakla.ru/api/yards`);
    return response.data.data as Mark[];
})

export const fetchMarkById = createAsyncThunk('marks/fetchById', async (id: number) => {
    const response = await axios.get(`https://api-moydvor.sakla.ru/api/yards/${id}`);
    console.log(response.data.data);
    return response.data.data as Mark;
})

export const updatePlace = createAsyncThunk('marks/updatePlace', async (arg: {data: any, id: number}) => {
    const { data, id } = arg;
    try {
        console.log(data);
        const response = await axios.put(`https://api-moydvor.sakla.ru/api/places/${id}`, data);
        return response.data;
    } catch (error) {
        // @ts-ignore
        if (error.response) {
            // @ts-ignore
            console.error('Ошибка при отправке формы: ', error.response.data);
        } else {
            // @ts-ignore
            if (error.request) {
                // Запрос был сделан, но ответ не был получен
                // @ts-ignore
                console.error('Ошибка при отправке формы: ', error.request);
            } else {
                // Что-то пошло не так при настройке запроса
                // @ts-ignore
                console.error('Ошибка: ', error.message);
            }
        }
    }
})

export const updateMark = createAsyncThunk('marks/update', async (arg: { data: any; id: number }) => {
    const { data, id } = arg;
    try {
        console.log(data);
        const response = await axios.put(`https://api-moydvor.sakla.ru/api/yards/${id}`, data);
        return response.data;
    } catch (error) {
        // @ts-ignore
        if (error.response) {
            // @ts-ignore
            console.error('Ошибка при отправке формы: ', error.response.data);
        } else {
            // @ts-ignore
            if (error.request) {
                // Запрос был сделан, но ответ не был получен
                // @ts-ignore
                console.error('Ошибка при отправке формы: ', error.request);
            } else {
                // Что-то пошло не так при настройке запроса
                // @ts-ignore
                console.error('Ошибка: ', error.message);
            }
        }
    }

})

export const addMark = createAsyncThunk('marks/add', async (data: any, { rejectWithValue }) => {
    try {
        console.log(data);
        const response = await axios.post(`https://api-moydvor.sakla.ru/api/yards`, data);
        return response.data;
    } catch (error) {
        // @ts-ignore
        if (error.response) {
            // Сервер ответил с кодом состояния, который выходит за пределы диапазона 2xx
            // @ts-ignore
            console.error('Ошибка при отправке формы: ', error.response.data);
            // Здесь вы можете добавить дополнительную логику обработки ошибки
        } else { // @ts-ignore
            if (error.request) {
                        // Запрос был сделан, но ответ не был получен
                        // @ts-ignore
                console.error('Ошибка при отправке формы: ', error.request);
                    } else {
                        // Что-то пошло не так при настройке запроса
                        // @ts-ignore
                console.error('Ошибка: ', error.message);
                    }
        }
    }
});

const markSlice = createSlice({
    name: 'marks',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchMarks.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchMarkById.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchMarks.fulfilled, (state, action: PayloadAction<Mark[]>) => {
                state.status = 'succeeded';
                state.marks = action.payload;
            })
            .addCase(fetchMarkById.fulfilled, (state, action: PayloadAction<Mark>) => {
                state.status = 'succeeded';
                state.mark = action.payload;
            })
            .addCase(fetchMarks.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message || null;
            });
    }
});

export default markSlice.reducer;
